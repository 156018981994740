import styled, {keyframes} from "styled-components";
import {createPortal} from "react-dom";
import Logo from "../../../assets/images/logoWait.png"

interface TimedSuspense2CtrlProps{
    className?:string;
    text?:string;
}

const TimedSuspense2Ctrl = (props:TimedSuspense2CtrlProps)=>{
    return createPortal(
        <div className={`timedSuspense2 ${props.className}`}>
            <div className="wrap_logo">
                <div className="in_logo">
                    <img src={Logo} alt={"Logo timed"}/>
                </div>
            </div>
            <div className="legende">
                {props.text ? props.text : "chargement en cours..."}
            </div>
        </div>, document.body
    )
}

const AnimationBounce = keyframes`
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
`

const TimedSuspense2 = styled(TimedSuspense2Ctrl)`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 9999;
    .wrap_logo{
        display: flex;
        justify-content: center;
    }
    .in_logo{
        width: 90px;
        height: 90px;
        animation: ${AnimationBounce} linear 0.9s infinite;
        img{
            width: 100%;
            height: auto;
        }
    }
    .legende{
        position: absolute;
        bottom: 30px;
        text-align: center;
        z-index: 3;
        width: 100%;
        color: ${props=>props.theme.NeutreLight};
    }
`

export default TimedSuspense2
