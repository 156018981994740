import {openDB} from "idb";

const dbPromise = openDB("TimedAppDB", 2, {
    upgrade(db){
        if (!db.objectStoreNames.contains("user")) {
            db.createObjectStore("user");
        }
        if(!db.objectStoreNames.contains("user_mob")){
            db.createObjectStore("user_mob");
        }
        if(!db.objectStoreNames.contains("actes")){
            db.createObjectStore("actes");
        }
        if(!db.objectStoreNames.contains("fonctions")){
            db.createObjectStore("fonctions");
        }
    }
})

// 🔥 Sauvegarder l'utilisateur après connexion
export async function saveUser(user: any) {
    const db = await dbPromise;
    await db.put("user", user, "currentUser");
}

// 🔥 Récupérer l'utilisateur depuis IndexedDB (offline)
export async function getUser():Promise<User> {
    const db = await dbPromise;
    return db.get("user", "currentUser");
}

// 🔥 Supprimer les données utilisateur (ex: logout)
export async function clearUserCache() {
    const db = await dbPromise;
    await db.delete("user", "currentUser");
}



// 🔥 Sauvegarder l'utilisateur après connexion
export async function saveUserMob(user: any) {
    const db = await dbPromise;
    await db.put("user_mob", user, "currentUserMob");
}

// 🔥 Récupérer l'utilisateur depuis IndexedDB (offline)
export async function getUserMob():Promise<MeMob> {
    const db = await dbPromise;
    return db.get("user_mob", "currentUserMob");
}

// 🔥 Supprimer les données utilisateur (ex: logout)
export async function clearUserMobCache() {
    const db = await dbPromise;
    await db.delete("user_mob", "currentUserMob");
}




export async function saveActes(acte: any) {
    const db = await dbPromise;
    await db.put("actes", acte, "actes");
}

// 🔥 Récupérer l'utilisateur depuis IndexedDB (offline)
export async function getActes():Promise<Acte[]> {
    const db = await dbPromise;
    return db.get("actes", "actes");
}

// 🔥 Supprimer les données utilisateur (ex: logout)
export async function clearActesCache() {
    const db = await dbPromise;
    await db.delete("actes", "actes");
}

export async function saveFonctions(fonctions: any) {
    const db = await dbPromise;
    await db.put("fonctions", fonctions, "fonctions");
}

// 🔥 Récupérer l'utilisateur depuis IndexedDB (offline)
export async function getFonctions():Promise<Fonction[]> {
    const db = await dbPromise;
    return db.get("fonctions", "fonctions");
}

// 🔥 Supprimer les données utilisateur (ex: logout)
export async function clearFonctionsCache() {
    const db = await dbPromise;
    await db.delete("fonctions", "fonctions");
}
