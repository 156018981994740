import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    addUserSignature, CancelSub,
    forgot_pwd,
    getInfosUser, getInfosUserMob,
    getUsersByCentre,
    getUsersByCentreBorn, getUserSignature, getUsersQuicks, getUsersShadow,
    modifyCoord, postWMSub,
    saveOptin, trashUserSignature
} from "./user.services";
import {toast} from "react-toastify";
import {getUser, getUserMob, saveUser, saveUserMob} from "../../indexedDB";


export const useGetUser=(suspense=true):UseQueryResult<User, Error>=>{
    return useQuery<User, Error>(["user_connected"], getInfosUserMob, {
        staleTime:100000,
        refetchOnWindowFocus:false,
        suspense:suspense
    })
}

export const useGetUserSignature = ():UseQueryResult<UserSignature|null, Error>=>{
    return useQuery(["user_signature"], getUserSignature)
}

export const useGetUserPre=(isConnected:boolean, suspense=true):UseQueryResult<User, Error>=>{
    return useQuery(["user_connected"], ()=>getInfosUserMob(), {
        staleTime: 1000 * 60 * 60 * 24, // Cache pendant 24h
        initialData: () => {
            let cachedUser;
            getUser().then(data => cachedUser = data); // 🔥 Chargement IndexedDB en tâche de fond
            return cachedUser || undefined;
        },
        onSuccess: async (data) => {
            await saveUser(data);
        },
        enabled:!!isConnected
    })
}

export const useGetUserMob=(suspense=true):UseQueryResult<MeMob, Error>=>{
    return useQuery<MeMob, Error>(["user_connected_mob"], getInfosUserMob, {
        staleTime:10000*5,
        cacheTime:10000*5,
        refetchOnWindowFocus:false,
        suspense:true
    })
}

export const useGetUserMobPre=(isConnected:boolean, suspense=true):UseQueryResult<MeMob, Error>=>{
    return useQuery<MeMob, Error>(["user_connected_mob"], getInfosUserMob, {
        staleTime: 1000 * 60 * 60 * 24, // Cache pendant 24h
        initialData: () => {
            let cachedUser;
            getUserMob().then(data => cachedUser = data); // 🔥 Chargement IndexedDB en tâche de fond
            return cachedUser || undefined;
        },
        onSuccess: async (data) => {
            await saveUserMob(data);
        },
        enabled:!!isConnected
    })
}

export const useSaveOptIn = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return saveOptin(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_connected"])
            }
        }
    )
}

export const useGetAllUser=():UseQueryResult<User[], Error>=>{
    return useQuery<User[], Error>(["users_all"], ()=>getUsersByCentre())
}


export const useGetUserBornNow=():UseQueryResult<UserBirth[], Error>=>{
    return useQuery<UserBirth[], Error>(["users_births"], ()=>getUsersByCentreBorn(), {

    })
}

export const useModifyCoord = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:{id:number, datas:ModifyUserFD})=>{
            return modifyCoord(data.datas, data.id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_connected"])
                queryClient.invalidateQueries(["users"])
            }
        }
    )
}

export const useForgotPwd = ()=>{
    return useMutation(
        (data:{username:string})=>{
            return forgot_pwd(data.username);
        }
    )
}

export const useTrashSignature = ()=>{
    return useMutation(
        (id:number)=>trashUserSignature(id)
    )
}
export const useAddSign = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (data:PostNewSignature) => {
            return addUserSignature(data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user"]);
                queryClient.invalidateQueries(["user_connected"]);
                toast.success("signature modifiée !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    );
};

export const useGetUsersShadow = (nom:string):UseQueryResult<UserShadow[], Error>=>{
    return useQuery(["users_shad", nom], ()=>getUsersShadow(nom), {
        enabled:nom!==""
    })
}


export const useSaveWMSub = ()=>{
    return useMutation(
        (datas:WMsubFormData)=>{
            return postWMSub(datas);
        }
    )
}

export const useCancelWMSub = ()=>{
    return useMutation(
        ()=>{
            return CancelSub();
        }
    )
}
